var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent",attrs:{"rounded":"lg"}},[_c('v-card-title',{staticClass:"mx-5 text-black px-0 py-1 ml-0 mr-0 fs-20 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"py-4"},[_c('v-card',[_c('div',{staticClass:"pa-5"},[_c('v-card-title',{staticClass:"text-black px-0 pb-7 pt-0 fs-20 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v("These keys will allow you to authenticate API requests.")])],1),_c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"bg-grey"},[_c('tr',[_c('th',{staticClass:"text-left px-5"},[_vm._v(" NAME ")]),_c('th',{staticClass:"text-left",staticStyle:{"max-width":"460px","margin-left":"0","padding":"0"}},[_vm._v(" TOKEN ")]),_c('th',{staticClass:"text-left px-5"},[_vm._v(" CREATED ")]),_c('th',{staticClass:"sr-only"})])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.publicKey && _vm.publicKey.name)+" ")]),_c('td',{staticStyle:{"width":"30%","padding":"20px 0","max-width":"460px"},on:{"click":function($event){return _vm.handleCopy(_vm.publicKey && _vm.publicKey.token)}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('code',_vm._g({staticStyle:{"background":"none","user-select":"none","cursor":"pointer","display":"block"},attrs:{"title":"Click to Copy"}},Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.publicKey && _vm.publicKey.token)+" ")])]}}])},[_c('span',[_c('svg',{attrs:{"width":"18","height":"18","fill":"none","viewBox":"0 0 18 18","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M15 6.75H8.25C7.42157 6.75 6.75 7.42157 6.75 8.25V15C6.75 15.8284 7.42157 16.5 8.25 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V8.25C16.5 7.42157 15.8284 6.75 15 6.75Z","stroke":"white","stroke-width":"1.6","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M3.75 11.25H3C2.60218 11.25 2.22064 11.092 1.93934 10.8107C1.65804 10.5294 1.5 10.1478 1.5 9.75V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H9.75C10.1478 1.5 10.5294 1.65804 10.8107 1.93934C11.092 2.22064 11.25 2.60218 11.25 3V3.75","stroke":"white","stroke-width":"1.6","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Click to Copy ")])])],1),_c('td',{staticClass:"px-5"},[_vm._v(" "+_vm._s(_vm.publicKey && _vm.publicKey.created_at)+" ")]),_c('td',[_c('v-menu',{attrs:{"top":"","close-on-click":true,"offset-y":"","offset-x":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({},'button',attrs,false),on),[_c('svg',{staticStyle:{"width":"20px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"}})])])]}}])},[_c('v-list',[_c('v-list-item',{staticStyle:{"min-height":"22px"}},[_c('v-list-item-title',{staticClass:"text-sm-body-1"},[_vm._v(" Revoke Key ")])],1)],1)],1)],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.secretKey && _vm.secretKey.name)+" ")]),_c('td',{staticClass:"d-flex flex-column",staticStyle:{"height":"auto","padding":"20px 0","max-width":"460px"}},[(_vm.showKey)?_c('div',{on:{"click":function($event){return _vm.handleCopy(_vm.secretKey && _vm.secretKey.token)}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('code',_vm._g({staticStyle:{"user-select":"none","background":"none","cursor":"pointer","display":"block"},attrs:{"title":"Click to Copy"}},Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.secretKey && _vm.secretKey.token)+" ")])]}}],null,false,2985046277)},[_c('span',[_c('svg',{attrs:{"width":"18","height":"18","fill":"none","viewBox":"0 0 18 18","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M15 6.75H8.25C7.42157 6.75 6.75 7.42157 6.75 8.25V15C6.75 15.8284 7.42157 16.5 8.25 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V8.25C16.5 7.42157 15.8284 6.75 15 6.75Z","stroke":"white","stroke-width":"1.6","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M3.75 11.25H3C2.60218 11.25 2.22064 11.092 1.93934 10.8107C1.65804 10.5294 1.5 10.1478 1.5 9.75V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H9.75C10.1478 1.5 10.5294 1.65804 10.8107 1.93934C11.092 2.22064 11.25 2.60218 11.25 3V3.75","stroke":"white","stroke-width":"1.6","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Click to Copy ")])])],1):_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.showKey = !_vm.showKey}}},[_vm._v(" Reveal Key ")]),(_vm.showKey)?_c('v-btn',{staticClass:"mt-4",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.showKey = !_vm.showKey}}},[_vm._v(" Hide Key ")]):_vm._e()],1),_c('td',{staticClass:"px-5"},[_vm._v(" "+_vm._s(_vm.secretKey && _vm.secretKey.created_at)+" ")]),_c('td',[_c('v-menu',{attrs:{"top":"","close-on-click":true,"offset-y":"","offset-x":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({},'button',attrs,false),on),[_c('svg',{staticStyle:{"width":"20px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"}})])])]}}])},[_c('v-list',[_c('v-list-item',{staticStyle:{"min-height":"22px"}},[_c('v-list-item-title',{staticClass:"text-sm-body-1"},[_vm._v(" Revoke Key ")])],1)],1)],1)],1)])])]},proxy:true}])})],1)])],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.Alert = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.Alert),callback:function ($$v) {_vm.Alert=$$v},expression:"Alert"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }